.rodape{
	background-color: #585570;
	color: #FFF;
	font-family: 'baloo_bhaijaanregular', sans-serif;

	a:hover{
		color: #FFF212;
	}

	@include media-breakpoint-down(xs) {
		padding-bottom: 67px;
		text-align: center;
	}
}

.bg-rodape{
	padding: 45px 0;
	background-image: var(--lazy-bg);
	background-color: #585570;
	background-position: center top;
}

.newsletter{
	padding: 20px 0;
	background-color: #FFF212;
	font-family: 'baloo_bhaijaanregular', sans-serif;

	h2{
		color: #FFF212;
		text-shadow: 0 0 9px rgba(#000,.4);
		line-height: 1;

		span{
			font-size: toEm(114, 80);
			line-height: 1;
		}

		@include media-breakpoint-up(xl) {
			font-size: 80px;
		}
	}

	.input-group{
		.form-control{
			border-radius: 54px 0 0 54px;
			padding-left: 30px;
			background: #FFF;
		}

		.btn{
			border-radius: 0 54px 54px 0;
			padding-left: 30px;
			padding-right: 30px;
		}
	}

	@include media-breakpoint-up(lg) {
		background-image: var(--lazy-bg);
		background-position: center top;
		background-repeat: repeat-y;
	}
}

.creditos{
	padding-top: 20px;
	font-size: 14px;

	svg{
		width: toEm(39, 14);
		height: toEm(20, 14);
	}

	.redes__sociais{
		a{
			background-color: currentcolor;

			& + a{
				margin-left: 15px;
			}
		}
	}
}

.central__atendimento{
	color: #FFF212;
	margin-bottom: 30px;
	line-height: 1;
	font-size: 20px;

	.content{
		font-size: toEm(50, 20);

		@include media-breakpoint-down(xs) {
			font-size: 35px;
		}
	}
}

.title-footer {
	color: #FFF212;
	font-size: 18px;
}

.nav__footer{
	padding-left: 0;
	list-style: none;

	@include media-breakpoint-up(lg) {
		li + li{
			margin-top: 30px;
		}
	}
}
