.form-control{
	background: #EEEEEE;
	border-color: #EEEEEE;
	border-radius: 0;
	font-family: 'baloo_bhaijaanregular', sans-serif;
	min-height: 55px;

	&::placeholder{
		color: #000;
	}
}

.custom-control-label::before{
	border-color: #DDD;
	background: #DDD;
}

.custom-control-input:checked ~ .custom-control-label::before{
	background: $red;
	border-color: $red;
}
