.produto{
	width: 100%;
	max-width: 255px;
	margin-right: auto;
	margin-left: auto;
	font-family: 'baloo_bhaijaanregular', sans-serif;
	text-align: center;

	.produto__foto{
		margin-bottom: 10px;
	}

	.produto__nome{
		line-height: 1.5;
		max-height: 3em;
		@include line-clamp;
	}

	.produto__rating{
		color: #DDDDDD;

		.fas{
			position: relative;

			&:after{
				color: #FDF004;
				position: absolute;
				left: 0;
			}
		}

		@for $i from 0 through 4{

			&[data-rating="#{$i}.5"] {
				.fas:nth-child(#{$i+1}):after{
					content: fa-content($fa-var-star-half);
				}
			}

			$b: $i +1;

			&[data-rating="#{$i+1}"],
			&[data-rating="#{$i}.5"] {
				@for $c from $i through 1 {
					.fas:nth-child(#{$c}){
						color: #FDF004;
					}
				}
			}
		}

		&[data-rating="5"]{
			color: #FDF004;
		}
	}

	.btn{
		color: #585570;
		width: 100%;
	}
}

.qtde__controls{
	display: flex;
	background: #EEEEEE;
	border-radius: 54px;

	.qtde__controls__input{
		@extend .form-control;
		min-height: 54px;
		text-align: center;
	}

	.qtde__controls__btn{
		min-width: 54px;
		height: 54px;
		border-radius: 50%;
		@include button-variant($red, $red);

		&.disabled{
			background: #bbb !important;
			border-color: #bbb !important;
			color: #FFF !important;
		}
	}
}

.produto__detalhes{
	font-family: 'baloo_bhaijaanregular', sans-serif;
}
