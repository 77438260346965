@import "_faixa__topo.scss";
@import "_mobile__controls.scss";
@import "_mobile__search.scss";
@import "_search.scss";


.backdrop{
	position: fixed;
	z-index: 2049;
	width: 100%;
	height: 100%;
	left:0;
	top: 0;
	background: rgba(#000,.7);
	animation: fadeIn 0.6s linear;
}


.topo {
	font-family: 'baloo_bhaijaanregular';

	.user{
		display: flex;
		margin: 0 15px;

		.icon{
			margin-right: 10px;
		}

		a:hover{
			color: #ED3237;
			text-decoration: none;
		}
	}

	.carrinho{
		a{
			display: inline-block;
			position: relative;
		}

		.fas{
			font-size: 37px;
			color: #ED3237;
		}

		.qtde{
			padding: 5px;
			position: absolute;
			perspective: 3px;
			min-width: 30px;
			color: #FFF;
			text-align:center;
			font-size: 12px;
			font-family: 'baloo_bhaijaanregular';
			right: 0;
			top: 0;
			transform: translate(50%,-50%);

			&:before{
				content:'';
				position: absolute;
				width: 100%;
				padding-bottom: 100%;
				border-radius: 50%;
				background: $blue-darkgrey;
				left:0;
				top:50%;
				transform: translateY(-50%);
				z-index: -1;
			}
		}
	}

	@include media-breakpoint-up(xl) {
		.content{
			max-width: map-get($container-max-widths, xl);
			padding-top: 30px;
			padding-bottom: 30px;
			display: flex;
			align-items: center;
		}

		.search{
			width: 367px;
			margin-left: auto;
			margin-right: auto;
		}
	}

	@include media-breakpoint-only(lg) {
		.content{
			padding-top: 30px;
			padding-bottom: 30px;

			.brand{
				width: 340px;
				margin-right: 30px;
			}

			.search{
				flex-grow: 1;
			}

			.carrinho{
				margin-right: 15px;
			}
		}
	}

	@include media-breakpoint-up(lg) {
		.content{
			width: 100%;
			margin-left: auto;
			margin-right: auto;
			padding-left: 15px;
			padding-right: 15px;
			max-width: map-get($container-max-widths, xl);
			padding-top: 30px;
			padding-bottom: 30px;
			display: flex;
			align-items: center;
		}
	}

	@include media-breakpoint-only(md) {
		.content{
			width: 100%;
			margin-left: auto;
			margin-right: auto;
			padding-left: 15px;
			padding-right: 15px;
			padding-top: 30px;
			padding-bottom: 30px;
			display: grid;
			grid-template-columns:  minmax(380px, auto) min-content min-content;
			grid-template-rows: repeat(2,auto);
			white-space: nowrap;

			.search{
				grid-column: 1/4;
				grid-row: 2/3;
				margin-top: 15px;
			}

			.carrinho{
				margin-right: 15px;
				margin-bottom: auto;
				margin-top: auto;
			}

			.user{
				margin: auto;
				padding: 0 30px;
			}
		}
	}

	@include media-breakpoint-only(sm) {
		.content{
			width: 100%;
			margin-left: auto;
			margin-right: auto;
			padding-left: 15px;
			padding-right: 15px;
			padding-top: 30px;
			padding-bottom: 30px;
			display: grid;
			grid-template-columns:  minmax(33%, auto) min-content min-content;
			grid-template-rows: repeat(2,auto);
			white-space: nowrap;

			.search{
				grid-column: 1/4;
				grid-row: 2/3;
				margin-top: 15px;
			}

			.carrinho{
				margin-right: 15px;
				margin-bottom: auto;
				margin-top: auto;
			}

			.user{
				margin: auto;
				padding: 0 30px;
			}
		}
	}
	@include media-breakpoint-only(xs) {
		.brand{
			a{
				display: flex;
			}

			img{
				width: 100%;
			}
		}
		.user,
		.search,
		.carrinho{
			display: none;
		}

		.user.show{
			display: block;
			position: fixed;
			top: 80px;
			background: #FFF;
			z-index: 2000;
			padding: 10px;
			right: 15px;
			width: 100%;
			max-width: 250px;
			border:1px solid #CCC;

			.icon__user{
				display: block;
				margin-right: auto;
				margin-left: auto;
				margin-bottom: 10px;
			}
		}
	}
}
