.mobile__controls{
	position: fixed;
	left: 0;
	top:0;
	right: 0;
	background: #FFF;
	border-bottom: 1px solid #CCC;
	width: 100%;
	z-index: 999;
	height: 70px;

	.btn{
		padding: 10px 5px;
		border-radius: 0;

		&:hover,
		&[aria-expanded="true"]{
			background: #EEE;
		}

		.fas,
		.far,
		.fab{
			font-size: 20px;
		}
	}

	.label{
		display: block;
		font-size: 13px;
		white-space: nowrap;
	}

	#toggle__user__mbl[aria-expanded="true"]{
		position: relative;

		&:before{
			content: '';
			position: absolute;
			border-left: 10px solid transparent;
			border-right: 10px solid transparent;
			border-bottom: 10px solid #FFF;
			z-index: 2000;
			left: 50%;
			margin-left: -15px;
			top: 71px;
		}
	}

	@include media-breakpoint-up(sm) {
		display: none;
	}

	@include media-breakpoint-down(xs) {
		display: flex;

		& > *{
			flex-grow: 1;
		}
	}

	.btn__carrinho{
		position: relative;

		.qtde{
			position: absolute;
			perspective: 3px;
			color: #FFF;
			padding: 4px;
			font-size: 10px;
			position: absolute;
			transform: translate(-25%,-25%);
			z-index: 3;

			&::before{
				content:'';
				display: block;
				position: absolute;
				background: $blue-darkgrey;
				width: 100%;
				padding-bottom: 100%;
				border-radius: 50%;
				left: 0;
				top: 50%;
				transform: translateY(-50%);
				z-index: -1;
			}
		}
	}
}

@include media-breakpoint-down(sm) {
	.topo{
		padding-top: 70px;
	}
}
