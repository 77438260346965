@font-face {
    font-family: 'baloo_bhaijaanregular';
    src: url('#{$fonts}/Baloo_Bhaijaan/baloobhaijaan-regular-webfont.eot');
    src: url('#{$fonts}/Baloo_Bhaijaan/baloobhaijaan-regular-webfont.eot?#iefix') format('embedded-opentype'),
         url('#{$fonts}/Baloo_Bhaijaan/baloobhaijaan-regular-webfont.woff2') format('woff2'),
         url('#{$fonts}/Baloo_Bhaijaan/baloobhaijaan-regular-webfont.woff') format('woff'),
         url('#{$fonts}/Baloo_Bhaijaan/baloobhaijaan-regular-webfont.ttf') format('truetype'),
         url('#{$fonts}/Baloo_Bhaijaan/baloobhaijaan-regular-webfont.svg#baloo_bhaijaanregular') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'montserratsemibold';
    src: url('#{$fonts}/montserrat_semi_bold/montserrat-semi-bold-webfont.eot');
    src: url('#{$fonts}/montserrat_semi_bold/montserrat-semi-bold-webfont.eot?#iefix') format('embedded-opentype'),
         url('#{$fonts}/montserrat_semi_bold/montserrat-semi-bold-webfont.woff2') format('woff2'),
         url('#{$fonts}/montserrat_semi_bold/montserrat-semi-bold-webfont.woff') format('woff'),
         url('#{$fonts}/montserrat_semi_bold/montserrat-semi-bold-webfont.ttf') format('truetype'),
         url('#{$fonts}/montserrat_semi_bold/montserrat-semi-bold-webfont.svg#montserratsemibold') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'montserratblack';
    src: url('#{$fonts}/montserrat-black/montserrat-black-webfont.eot');
    src: url('#{$fonts}/montserrat-black/montserrat-black-webfont.eot?#iefix') format('embedded-opentype'),
         url('#{$fonts}/montserrat-black/montserrat-black-webfont.woff2') format('woff2'),
         url('#{$fonts}/montserrat-black/montserrat-black-webfont.woff') format('woff'),
         url('#{$fonts}/montserrat-black/montserrat-black-webfont.ttf') format('truetype'),
         url('#{$fonts}/montserrat-black/montserrat-black-webfont.svg#montserratblack') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;

}
