.btn-pill{
	border-radius: 2rem;
	padding: 0.75rem 1.5rem;
}

.btn-voltar{
	min-width: 180px;
	font-family: 'montserratsemibold', sans-serif;
	padding: 1rem 1.5rem;
}


.btn-black{
	position: relative;
	perspective: 3px;
	overflow: hidden;
	background: #000000;
	color: #FFF;

	&:hover,
	&:focus{
		background: lighten(#000, 15%);
		color: #FFF;
	}
}
