.search{
	.input__search{
		position: relative;
	}

	.input__search__input{
		background: #EEEEEE;
		border-radius: 30px;
		min-height: 60px;
		display: block;
		width: 100%;
		border: 1px solid #EEE;
		padding: 0.325rem 60px 0.325rem 30px;
		outline: none !important;
	}

	.input__search__btn{
		$bg: #ED3237;
		background: $bg;
		color: #FFF;
		width: 52px;
		height: 52px;
		display: flex;
		align-items: center;
		justify-content: center;
		border-radius: 50%;
		position: absolute;
		right: 4px;
		top: 4px;
		border: none;
		transition: all 0.3s linear;

		&:hover{
			background: darken($bg,10%);
		}
	}

	@include media-breakpoint-down(xs) {
		&:not(.mobile__search){
			display: none;
		}
	}
}
