#banner{
	overflow: hidden;
	width: 100%;
	max-width: 1920px;
	margin-left: auto;
	margin-right: auto;

	.carousel-inner{
		width: percentage(1920 / 1300);
		max-width: 1920px;
		left: 50%;
		transform: translateX(-50%);
	}
}

.deparatamentos__nav{
	background-color: #585570;
	color: #FFF;
	font-family: 'baloo_bhaijaanregular', sans-serif;
	text-align:center;
	padding: 25px 0;
	font-size: 14px;

	.row{
		align-items: center;
	}

	h2{
		font-size: 16px;
		text-align: left;
		padding-left: 15px;

		.fas{
			display: block;
			margin-left: -15px;
			width: 15px;
			font-size: 2em;
			float: left;
		}

		&:after{
			content:'';
			display: table;
			clear: both;
		}
	}

	.categoria{
		.icon-cat{
			width: 40px;
			height: 40px;
			mask-image: var(--icon);
			mask-size: 100% 100%;
			mask-position: center center;
			background-color: currentColor;
			margin-left: auto;
			margin-right: auto;
			margin-bottom: 10px;
		}

		a:hover{
			color:#FFF212;
			text-decoration: none;
		}
	}

	@include media-breakpoint-up(md) {
		.container{
			display: flex;
			align-items: center;
		}

		h2{
			margin-right: 30px;
			min-width: 180px;
		}

		.owl-carousel{
			flex-grow: 1;
			width: calc(100% - 210px);
		}

	}

	.owl-carousel{
		padding-right: 40px;
		padding-left: 40px;
	}

	.owl-prev,
	.owl-next{
		background: #FFF212 !important;
		top: 50% !important;
		transform: translateY(-50%) !important;
		color: #000;
		width: 35px !important;
		height: 35px !important;

		&.disabled{
			display: none !important;
		}
	}

	.owl-stage{
		display: flex;
		align-items: center;
	}
}

.diferenciais{
	background: url(../images/bg-diferenciais.png) center top;
	padding-top: 30px;
	margin-bottom: 54px;
}

.section{
	text-align: center;
	padding: 60px 0;
	font-family: 'baloo_bhaijaanregular', sans-serif;

	h1{
		color: #585570;
	}
}


.owl-carousel{
	.owl-next,
	.owl-prev{
		background: #585570;
		width: 52px;
		height: 52px;
		display: inline-flex;
		align-items: center;
		justify-content: center;
		border-radius: 50%;
		top: 138px;
		position: absolute;

		&:not(.disabled):hover{
			background: #FFF212;
			cursor: pointer;
		}

		&.disabled{
			opacity: 0.5;
			cursor: default;
		}
	}

	@include media-breakpoint-up(xl) {
		.owl-prev{
			right: calc(100% + 15px);
		}

		.owl-next{
			left: calc(100% + 15px);
		}
	}

	@include media-breakpoint-down(lg) {
		.owl-prev{
			left: 0;
		}

		.owl-next{
			right: 0;
		}
	}
}
