.btn__whatsapp{
    position: fixed;
    left: 15px;
    bottom: 15px;
    width: 60px;
    height: 60px;
    box-shadow: 0 0 10px rgba(#000,.4);
	perspective: 3px;
	z-index: 999;

    &,
    &:before,
    .fab{
        border-radius: 50%;
    }

    &,
    &:before{
        background: linear-gradient(to bottom, #57F58C, #1DCB31);
    }

    &:before{
        content: '';
        animation: whatsappPulse 1s linear infinite;
    }

    &:before,
    .fab{
        position: absolute;
        width: 100%;
        height: 100%;
    }

    .fab{
        overflow: hidden;
        font-size: 40px;
        color: #FFF;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}

@keyframes whatsappPulse{
    from{
        opacity: 0.6;
        transform: scale(1);
    }

    to{
        opacity: 0;
        transform: scale(1.5);
    }
}
