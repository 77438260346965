.meu__pedido{
	background: #EEEEEE;
	margin-bottom: 25px;
	width: 100%;
	max-width: 800px;
	margin-left: auto;
	margin-right: auto;
	color: #444;
	font-family: 'baloo_bhaijaanregular', sans-serif;
	font-size: 14px;

	.meu__pedido__header{
		background: #57556E;
		color: #FFF;
		padding: 10px;
		font-size: 12px;

		.numero{
			font-size: 15px;
			margin-bottom: 10px;
		}
	}

	.meu__pedido__body{
		padding: 10px;

		.title{
			color: #263351;
		}

		.btn{
			padding: 15px 15px;
		}
	}

	@include media-breakpoint-up(md) {
		.meu__pedido__header{
			width: 240px;
		}
	}

	@include media-breakpoint-up(sm) {
		display: flex;

		.meu__pedido__header{
			margin-right: auto;
		}

		.meu__pedido__body{
			display: flex;
			align-items: center;

			.btn{
				margin-left: 10px;
			}
		}
	}

	@include media-breakpoint-down(xs) {
		// text-align: center;

		.meu__pedido__body{
			.btn{
				width: 100%;
				margin-top: 10px;
			}
		}
	}
}

.pedido{
	.pedido__item{
		background: #eee;
		margin-bottom: 25px;
		font-family: 'baloo_bhaijaanregular', sans-serif;

		@include media-breakpoint-up(sm) {
			display: flex;

			.pedido__qtde{
				margin-left:auto;
			}
		}
	}

	.pedido__header{
		display: flex;

		.pedido__foto{
			min-width: 100px;
		}

		.pedido__nome{
			margin: 10px;
			flex-grow: 1;
			max-height: 3em;
			overflow: hidden;
			display: -webkit-box;
			-webkit-box-orient: vertical;
			-webkit-line-clamp: 2;
			line-height: 1.5;
			align-self: center;
		}
	}

	.pedido__qtde{
		display: flex;
		align-items: center;
		white-space: nowrap;

		.qtde{
			font-size: 30px;
			padding: 10px;
		}

		.title{
			color: #57546D;
			font-size: 16px;
			padding: 10px;
		}
	}
}
