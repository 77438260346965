.page__cadastro{

	h1,h2,h3,h4,h5,h6{
		font-family: 'baloo_bhaijaanregular', sans-serif;
	}

	label{
		font-family: 'baloo_bhaijaanregular', sans-serif;
	}
}

.form__cadastro{

	header{
		color: #000;

		h3{
			margin-bottom: 0;
			font-size: 16px;
		}
	}

	.tipos__pessoa{
		padding-left: 0;
		list-style: none;
		font-family: 'baloo_bhaijaanregular', sans-serif;
		color: #000;
		display: flex;
		margin-bottom: 0;
		margin-left: -10px;
		margin-left: -10px;

		li{
			padding-left: 10px;
			padding-right: 10px;
		}

		a{
			transition: 0.3s;
			&:before{
				content:'';
				width: 20px;
				height: 20px;
				border-radius: 50%;
				background: #DDD;
				border-radius: 50%;
				vertical-align: middle;
				margin-right: 5px;
				display: inline-block;
				transition: 0.3s;
			}

			&:hover{
				text-decoration: none;
				color: $red;
			}
		}

		.active a:before{
			background:$red;
		}
	}
}

.form__pessoa{
	color:#000;

	&:not(.active) {
		display: none;
	}

	h2{
		font-size: 17px;
	}

	legend{
		font-size: 14px;
		font-family: 'baloo_bhaijaanregular', sans-serif;
		margin-bottom: 20px;
	}

	label{
		color: #000;
	}

	fieldset + fieldset{
		margin-top: 50px;
	}
}
