.faixa__topo{
	padding-top: 10px;
	padding-bottom: 10px;
	background: $blue-darkgrey;
	color: #FFF;
	font-size: 14px;

	@include media-breakpoint-up(md) {
		font-size: 13px;

		.container{
			display: flex;
			justify-content: space-between;
			flex-wrap: wrap;
		}
	}

	@include media-breakpoint-only(sm) {
		font-size: 13px;

		.container{
			display: flex;
			justify-content: center;
			flex-wrap: wrap;
		}
	}
}

.contatos__topo{
	.fones{
		padding-left: 0;
		list-style: none;
		margin-bottom: 0;
		display: flex;

		li + li:before{
			content: '|';
			margin-left: 10px;
			margin-right: 10px;
			display: inline-block;
		}
	}

}

#menu{
	ul{
		list-style: none;
		padding-left: 0;
		margin-bottom: 0;
	}

	@include media-breakpoint-up(md) {
		border-left: 1px solid rgba(#FFF,.35);
	}

	@include media-breakpoint-only(sm) {
		margin-top: 15px;
	}

	@include media-breakpoint-up(sm) {
		font-size: 13px;
		padding-left: 15px;
		align-self: center;

		ul{
			display: flex;

			li + li{
				margin-left: 25px;
			}
		}

		a:hover{
			color: #FFF;
			text-decoration: none;
		}
	}
}

@include media-breakpoint-up(sm) {
	.contatos__topo{
		display: flex;

		.title{
			margin-right: 10px;
		}
	}
}

@include media-breakpoint-only(xs) {
	.contatos__topo{
		text-align: center;

		.fones{
			justify-content: center;
		}
	}

	#menu{
		position: fixed;
		left: 5px;
		padding: 0;
		list-style: none;
		top: 80px;
		background: #FFF;
		filter: drop-shadow(0 0 10px rgba(#000,0.4));
		z-index: 2000;
		color: $blue-darkgrey;

		&:before{
			content:'';
			position: absolute;
			border-left: 10px solid transparent;
			border-right: 10px solid transparent;
			border-bottom: 10px solid #FFF;
			left: 20px;
			bottom: 100%;
		}

		a{
			display: block;
			padding: 10px 15px;

			&:hover{
				text-decoration: none;
				background: #eee;
			}
		}

		.active a,
		.active a:hover{
			background: $blue-darkgrey;
			color: #FFF;
		}

		&:not(.menu--shown),
		&:not(.menu--hide) {
			display: none;
		}

		&.menu--shown{
			display: block;
			animation: fadeIn 0.6s linear;
		}
	}
}
