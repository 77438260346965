
.carrinho__header{
	display: flex;
	font-family: 'baloo_bhaijaanregular', sans-serif;

	.ttl{
		padding-bottom: 10px;
		border-bottom: 5px solid #E5E5E5;
		font-size: 16px;
	}

	.ttl:first-child{
		flex-grow: 1;
	}

	@include media-breakpoint-up(md) {
		.ttl:last-child{
			min-width: 250px;
			margin-left: 20px;
		}
	}

	@include media-breakpoint-down(sm) {
		display: none;
	}
}

.carrinho__body{
	margin-bottom: 30px;
}


.carrinho__item{
	border-bottom: 1px solid #E5E5E5;
	padding: 25px 0;
	font-family: 'baloo_bhaijaanregular', sans-serif;

	.carrinho__description{
		max-height: 4.5em;
		line-height: 1.5;
		overflow: hidden;
		@include line-clamp(3);
	}

	.btn-remover{
		background: #EEE;
		text-align: left;
		display: inline-flex;
		border-radius: 54px;
		max-height: 56px;
		align-items: center;
		font-family: 'baloo_bhaijaanregular', sans-serif;
		max-width: 255px;

		span{
			display: block;
			flex-grow: 1;
			text-align: center;
			padding-right: 25px;
		}

		.fas{
			display: inline-flex;
			width: 54px;
			height: 54px;
			background: red;
			margin-top: -6px;
			margin-bottom: -6px;
			margin-left: -0.75rem;
			border-radius: 50%;
			align-items: center;
			justify-content: center;
			color: #FFF;
		}
	}

	@include media-breakpoint-up(md) {
		display: grid;

		grid-template-columns: 160px auto 250px;
		grid-template-rows: repeat(2, auto);

		.carrinho__foto{
			grid-row: 1/3;
			grid-column: 1/2;
		}

		.carrinho__description{
			margin: 0 15px;
		}

		.btn-remover{
			margin-left: 15px;
			margin-top: 15px;
		}
	}

	@include media-breakpoint-down(sm) {
		display: grid;

		grid-template-columns: 90px auto;
		grid-template-rows: repeat(2, auto);

		.carrinho__foto{
			grid-row: 1/2;
			grid-column: 1/2;
		}

		.carrinho__description{
			margin: 0 15px;
			grid-row: 1/2;
			grid-column: 2/3;
		}

		.carrinho__qtde{
			grid-column: span 2;
			margin: 15px 0;
		}

		.btn-remover{
			grid-column: span 2;
			max-width: 100%;
		}
	}
}


.carrinho__footer{
	.btn{
		min-height: 63px;
		font-size: 12px;
		display: inline-flex;
		align-items: center;
		justify-content: center;
	}
}
