.mobile__search{
	position: fixed;
	top: 80px;
	left: 50%;
	transform: translate(-50%, var(--y, 0px));
	transition: transform 0.6s linear;
	width: calc(100% - 30px);
	max-width: 400px;
	z-index: 2050;
	text-align: center;
	display: block !important;

	&:not(.mobile__search--shown) {
		--y: calc((100% + 90px) * -1);
	}

	label{
		color: #FFF;
	}
}
