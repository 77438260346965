@import "_home.scss";

.internas{
	padding-bottom: 80px;

	.btn-enviar{
		padding: 1rem 1.5rem;
		min-width: 170px;
		font-family: 'montserratsemibold', sans-serif;
	}
}

.page-header{
	background: $blue-darkgrey;
	color: #FFF;
	padding: 10px 0;
	font-family: 'montserratsemibold', sans-serif;
	margin-bottom: 55px;

	h1{
		font-size: 12px;
		margin: 0;
		text-align: right;
	}
}

.title-page{
	font-family: 'montserratblack', sans-serif;
	color: $blue-darkgrey;
	font-size: 23px;
	padding-bottom: 20px;
	border-bottom: 11px solid currentColor;
	margin-bottom: 45px;
}


.conteudo__editavel{
	margin-bottom: 40px;

	img{
		max-width: 100%;
		height: auto !important;
	}
}
