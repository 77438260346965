// Margins
@for $i from 1 through 20 {
	$m: $i * 5;

	@include margin-variant($m);
}

[data-owl]{

	&:not(.ready){
		display: none;
	}

	&.disabled{
		cursor: not-allowed;
		opacity: 0.6;
	}
}


.slick-slider:not(.slick-initialized) {
	opacity: 0;
}
