.forms__login{
	h3{
		font-family: 'baloo_bhaijaanregular', sans-serif;
		font-size: 18px;
		margin-bottom: 20px;
	}

	.lbl,
	label{
		font-family: 'baloo_bhaijaanregular', sans-serif;
	}
}
