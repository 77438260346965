// bg-position(x,y) width height
$icons: (
	'icon__facebook': -47px 0px 36px 36px,
	'icon__instagram': 0px -47px 36px 36px,
	'icon__user': 0px 0px 37px 37px,
	'icon__youtube': -46px -47px 32px 32px,
	'owl_next': -93px 0px 14px 18px,
	'owl_prev': -93px -28px 14px 18px,
);

@if $nativeCssIconVarialables {
	:root{
		@each $icon,$value in $icons {
			/*icon-#{$icon}*/
			--icon-#{$icon}-width: #{nth($value, 3)};
			--icon-#{$icon}-height: #{nth($value, 4)};
			--icon-#{$icon}-bg: #{nth($value, 1)} #{nth($value, 2)};
		}
	}
}

.icon{
	background-image: url('../images/sprites.png');
	background-repeat:no-repeat;
	background-size: 107px 83px;

	display: inline-block;
	vertical-align: middle;

}

.icon__mask{
	mask-image: url('../images/sprites.png');
	mask-repeat:no-repeat;
	mask-size: 107px 83px;

	display: inline-block;
	vertical-align: middle;

}

@each $icon,$value in $icons {
	.#{$icon} {
		width: nth($value,3);
		min-width: nth($value,3);
		height: nth($value,4);
		min-height: nth($value,4);
		$sprite-position: #{nth($value,1)} #{nth($value,2)};

		&.icon{
			background-position: $sprite-position;
		}

		&.icon__mask{
			mask-position: $sprite-position;
		}
	}
}
